//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable @typescript-eslint/no-var-requires */
import Vue from 'vue';
import { getOrderConfirm, createPaymentIntent } from '@/api/home';
import {
  Sku,
  Cell,
  Popup,
  Button,
  RadioGroup,
  Radio,
  Dialog,
} from 'vant';
import { param2Obj } from '@/utils/help';
import { ERROR_OK } from '@/api/config';
import { payClient } from './client';

Vue.use(Sku);
Vue.use(Cell);
Vue.use(Popup);
Vue.use(Button);
Vue.use(RadioGroup);
Vue.use(Radio);
Vue.use(Dialog);

// const PIC = require('@/assets/product/nf.jpg');

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    sku: {
      type: Object,
      require: true,
    },
    params: {
      type: Object,
      require: true,
    },
    buyText: {
      type: String,
      default: 'Confirm',
    },
  },
  data() {
    return {
      showBuy: false,
      goods: {
        picture: '',
      },
      initialSku: {
        monthNum: 1,
        screens: 1,
      },
      selectedSkuComb: null,
      showPay: false,
      payInfo: {
        payDetailList: [],
      },
      payWay: '',
      showStripe: false,
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.showBuy = val;
      }
    },
    showBuy(val) {
      if (!val) {
        this.$emit('update:visible', false);
      }
    },
  },
  computed: {
    originPriceTips() {
      const tips = [];
      if (this.selectedSkuComb) {
        const { discount, originalPrice } = this.selectedSkuComb;
        tips.push((originalPrice / 100).toFixed(2));
        tips.push(`(-${(discount / 100).toFixed(2)}%)`);
      }
      return tips;
    },
    selectedText() {
      return this.$refs.sku ? this.$refs.sku.selectedText.substring(2) : '';
    },
    PayItemDesc() {
      let ret = {};
      const { payDetailList } = this.payInfo;
      const findIndex = payDetailList.findIndex((item) => item.payMethodId === this.payWay);
      if (findIndex > -1) {
        ret = { ...payDetailList[findIndex] };
      }
      return ret;
    },
  },
  methods: {
    handleSkuSelected({ selectedSkuComb }) {
      this.selectedSkuComb = selectedSkuComb;
    },
    onAddCartClicked(skuData) {
      const { id } = skuData.selectedSkuComb;
      const params = {
        carSeatId: '',
        isRenewalBill: '',
        productId: '',
        productNum: 1,
        ticketNo: '',
      };
      Object.assign(params, this.params, {
        productId: id,
      });
      const orderJsonStr = JSON.stringify({ orderItemVOList: [params] });
      const { sid } = param2Obj(window.location.search);
      getOrderConfirm({ orderJsonStr, sid }).then((res) => {
        if (res.code === ERROR_OK) {
          this.payInfo = res.data || {};
          const { payDetailList } = res.data || {};
          if (payDetailList.length > 0) {
            this.payWay = payDetailList[0].payMethodId;
          }
          this.showBuy = false;
          this.showPay = true;
        }
      });
    },
    handleConfirmMethod() {
      const { pjt, payMethodId, link } = this.PayItemDesc;
      if (pjt === 0) {
        createPaymentIntent({ orderId: this.payInfo.orderId }).then((res) => {
          if (res.code === ERROR_OK) {
            const { clientPK, clientSecret } = res.data;
            this.showStripe = true;
            this.$nextTick(() => {
              payClient({ clientPK, clientSecret });
            });
          }
        });
      } else if (pjt === 1) {
        const { token, key } = this.$store.getters;
        window.open(`${link}?token=${token}&key=${key}&pjt=${pjt}&payMethodId=${payMethodId}&orderId=${this.payInfo.orderId}`, '_self');
      } else if (pjt === 2) {
        window.open(this.PayItemDesc.link);
        setTimeout(() => {
          Dialog.alert({
            message: ' Was the payment successful?',
            confirmButtonText: 'Confirm',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
          }).then(() => {
            this.$router.push({ path: '/paysuccess' });
          }).catch();
        }, 1000);
      }
    },
  },
};
